import { NextPage } from "next";
import { Grid, Typography } from "@mui/joy";
import { centreOfPageAlt } from "../styles/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed } from "@fortawesome/pro-solid-svg-icons";

const PageNotFoundPage: NextPage = () => {
	return (
		<Grid container padding={"2rem"} sx={centreOfPageAlt}>
			<FontAwesomeIcon icon={faDoorClosed} style={{ fontSize: "4rem", color: "var(--joy-palette-danger-400)", marginRight: "1rem" }}/>
			<Typography level="h1" sx={{ marginTop: "1rem", marginBottom: "3rem" }}>Oops. Page not found.</Typography>
			<Typography level="h3" maxWidth={"30rem"}>This page doesn&apos;t seem to exist. Head back to where you were before.</Typography>
			<Typography level="body-lg" maxWidth={"30rem"} marginTop={"2rem"}>For any urgent queries contact our support team at support@careiq.health</Typography>
		</Grid>
	);
};

export default PageNotFoundPage;